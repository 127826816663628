const Loading = () => {
  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <div className="row justify-content-center main">
            <div className="spinner-grow text-success m-5" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Loading;
