const Question = ({
    handleClick,
    currentQuestion,
    questions,
    seconds,
    ...otherProps
  }) => (
    <div className="container">
      <div className="row">
        <div className="col">
          {/* <div className="timer-text">
            {seconds}
        </div>       */}
          <div className="question-section">
            {/* <div className="question-count">
              <span>{currentQuestion + 1}</span>/{questions.length}
            </div> */}
            <div className="question-text">           
              <h3 className="display-5">
                {currentQuestion.question}
              </h3>
            </div>
          </div>
          <div className="answer-section">
            <ul>
            {currentQuestion.answers.map((answer, i) => (
              <li 
              key={i}
              onClick={() => handleClick(currentQuestion, i)}>
                <button
                  className="answer-button"
                  key={i}
                >
                {answer.answer}
                </button>
              </li>

            ))}
            </ul>            
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="center q-image">
            <img
              src={currentQuestion.img}
              alt=""
              className="img-fluid"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
  
  export default Question;
  