import Question from './Question-step-2'

const Step2 = ({
    handleClick,
    questions,
    currentQuestion,
    seconds
}) => {
  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
            {/* <small>Fase 2 {profile}</small> */}
            <Question
              key={0}
              handleClick={handleClick}
              questions={questions}
              currentQuestion={currentQuestion}
              seconds={seconds}
            />
        </div>
      </div>
    </div>
  );
};

export default Step2
