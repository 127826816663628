const StartPage = ({start, image}) => {
    return(
        <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col question-text">
                <h3>Non sai da dove cominciare?</h3>
                <h4 className="nobold">Rispondi al questionario e scopri i corsi più adatti al tuo profilo e ai tuoi obiettivi professionali</h4>                
                <br />
                <p>
                  <button onClick={start} className="start">
                    Inizia ora<i className="fa fa-play" aria-hidden="true"></i>
                  </button>
                </p>
              </div>
              <div className="col">
                <img src={image} alt="React Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default StartPage