import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'
const Noresult = ({ restart }) => {

    return (
      <div className="container">
        <div className="card">
          <div className="card-body question-text">           
            <h3><FontAwesomeIcon icon={faTag} /> Ops!</h3>
            <p>
                Le informazioni fornite non sono sufficienti, prova a rifare l'assessment.
            </p>
            <button onClick={restart} className="start">
                Ricomincia <i className="fa fa-play" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    );
};

export default Noresult;
