import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import Plus from "../catalogoplus.png";
const Step3 = ({ profile, values, tags, courses, restart }) => {
  if (courses.length) {
    return (
      <div className="container result">
        <div className="card">
          <div className="card-body question-text">
            <h3 className="display-5">Ecco un elenco di corsi tagliati sul tuo profilo e i tuoi obiettivi professionali:</h3>
            <ul>
              {courses.map((course, index) => {
                return (
                  <li key={index} className="corso">   
                    <a href={course.url} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} color="#228E8E" />                     
                      &nbsp; {course.title} &nbsp;
                      { course.areaTematica === 'CATALOGO +' && (
                        <img src={Plus} alt="CATALOGO +" title="CATALOGO +" width={15} />
                      )}
                      {/* &nbsp; <img src={Plus} alt="CATALOGO +" title="CATALOGO +" width={15} />  */}
                    </a>
                  </li>
                );
              })}
            </ul>
            <br />
            <h5><i>Clicca sul titolo del corso per accedere alla scheda descrittiva</i></h5>
            <br />
            <p>
              La lista proposta rappresenta solo un campione dei corsi disponibili per il tuo profilo. 
              Svolgi nuovamente il questionario per ottenere risultati diversi, 
              oppure consulta il catalogo alla fine di questa pagina per scoprire tutti corsi che hai a disposizione.
            </p>
            <br />
            <button onClick={restart} className="start">
                Ricomincia <i className="fa fa-play" aria-hidden="true"></i>
            </button>
            <a href="#catalogo" rel="nooper" className='start'>Vai al catalogo</a>
          </div>
        </div>
      </div>
    );
  }
};

export default Step3;
