const domande = [
  {
    question:
      "Seleziona l'affermazione che più ti descrive: in un progetto la cosa più importante è...",
    answers: [
      {
        answer: "Raccogliere il maggior numero possibile di informazioni utili",
        tags: [],
        profile: ["logico", "analista", "pioniere"],
        question: "Nei miei processi valutativi...",
        answers: [
          {
            answer: "Resto fedele a un mio modello consolidato di lavoro",
            tags: [],
            profile: ["logico"],
          },
          {
            answer: "Faccio ricerche esterne e analizzo casi di studio",
            tags: [],
            profile: [],
            question: "La mia migliore qualità è:",
            answers: [
              {
                answer: "Il mio problem solving",
                tags: [],
                profile: ["analista"],
              },
              {
                answer: "La mia capacità critica",
                tags: [],
                profile: ["logico"],
              },
              {
                answer: "La mia propensione all'innovazione",
                tags: [],
                profile: ["pioniere"],
              },
            ],
          },
        ],
      },
      {
        answer: "Organizzare metodicamente il lavoro di tutto il team",
        tags: [],
        profile: ["stratega", "analista", "condottiero"],
        question: "Nella guida di un piano ha più importanza:",
        answers: [
          {
            answer:
              "La rilevazione di scostamenti o implementazioni di azioni correttive",
            tags: [],
            profile: ["stratega"],
          },
          {
            answer:
              "L'ottimizzazione delle risorse per il raggiungimento degli obiettivi",
            tags: [],
            profile: [],
            question: "La mia migliore qualità è:",
            answers: [
              {
                answer: "Il mio problem solving",
                tags: [],
                profile: ["analista"],
              },
              {
                answer: "La mia capacità organizzativa e di delega",
                tags: [],
                profile: ["stratega"],
              },
              {
                answer: "La mia capacità di coordinare efficacemente il team",
                tags: [],
                profile: ["condottiero"],
              },
            ],
          },
        ],
      },
      {
        answer: "Tenere aggiornato il team in maniera puntuale e precisa",
        tags: [],
        profile: ["diplomatico", "condottiero", "bardo"],
        question: "Quando si lavora in gruppo, è più importante...",
        answers: [
          {
            answer:
              "Coinvolgere le risorse del team nella gestione delle problematiche",
            tags: [],
            profile: ["Diplomatico"],
          },
          {
            answer: "Comunicare obiettivi in maniera chiara",
            tags: [],
            profile: [],
            question: "La mia migliore qualità è:",
            answers: [
              {
                answer: "La mia capacità di fare networking",
                tags: [],
                profile: ["diplomatico"],
              },
              {
                answer: "La mia capacità di coordinare efficacemente il team",
                tags: [],
                profile: ["condottiero"],
              },
              {
                answer: "La mia capacità di appianare le divergenze",
                tags: [],
                profile: ["bardo"],
              },
            ],
          },
        ],
      },
      {
        answer: "Dare spazio a nuove idee",
        tags: [],
        profile: ["pioniere", "creativo", "bardo"],
        question: "Pensi che una chiara definizione di ruoli e processi...",
        answers: [
          {
            answer: "Rischi di diminuire la creatività della risorse",
            tags: [],
            profile: ["creativo"],
          },
          {
            answer: "Agevoli sempre la riuscita del progetto",
            tags: [],
            profile: [],
            question: "La mia migliore qualità è:",
            answers: [
              {
                answer: "Sono bravo a coordinare le idee del team",
                tags: [],
                profile: ["bardo"],
              },
              {
                answer: "Sono sempre aggiornato sulle novità del mio settore",
                tags: [],
                profile: ["pioniere"],
              },
              {
                answer: "Penso fuori dagli schemi e trovo soluzioni originali",
                tags: [],
                profile: ["creativo"],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default domande;