import Question from './Question'

const Step1 = ({
    showProfile,
    profile,
    start,
    handleClick,
    questions,
    currentQuestion,
    seconds
}) => {
  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          {showProfile ? (
            <div>
              <h1>Step 1 completato</h1>
              profilo:
              {profile}
              <button onClick={start} className="start">
                Iniziamo il test per {profile}
              </button>
            </div>
          ) : (
            <Question
              key={0}
              handleClick={handleClick}
              questions={questions}
              currentQuestion={currentQuestion}
              seconds={seconds}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Step1
