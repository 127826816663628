import React, { useState, useEffect } from "react";
import axios from "axios";
import StartPage from "./components/StartPage";
import Loading from "./components/Loading";
import Step1 from "./components/Step-1";
import Step2 from "./components/Step-2";
import Step3 from "./components/Step-3";
import Noresult from "./components/Noresult";
import Circles from "./circles.svg";
import "./App.css";
import domande from "./api/step-1";

function App() {
  const [step, setStep] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [tags, setTags] = useState([]);
  const [lowRatingtags, setlowRatingtags] = useState([]);
  const [percent, setPercent] = useState([]);
  const [courses, setCourses] = useState([]);
  const [profile, setProfile] = useState("");
  // const [showScore, setShowScore] = useState(false);
  // const [showProfile, setShowProfile] = useState(false);
  const [values, setValues] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [step2Questions, setStep2Questions] = useState([]);
  // const [email, setEmail] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [userToken, setUserToken] = useState("");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setQuestions(domande);
    // console.table(domande)
  }, []);

  /**
   * fetchquestion step 2
   * @param profilo
   * TODO
   */
  const fetchQuestions = async (profile) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}questions/profile/${profile}`)
      .then((response) => {
        // Handle response
        setStep2Questions(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        // Handle errors
        console.error(err);
      });
  };

  /**
   * Torna i corsi in base a profilo / tag / limit
   * @param {*} profilo 
   * @param {*} tag 
   * @param {*} limit 
   */
  const fetchCourses = async (profilo, tag, limit) => {

    // let data = {}
    // data.profilo = profilo
    // data.tag = tag[0]
    // data.limit = limit

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}corsi/output`        
      ,{
        profilo: profilo[0],
        tag: tag,
        limit: limit
      })
      .then((response) => {
        courses.push(response.data);
        const flatted = [].concat.apply([],courses)
        // se dio vole scartamo i
        const uniqueFlatted = Array.from(new Set(flatted.map(a => a.riferimento)))
          .map(riferimento => {
            return flatted.find(a => a.riferimento === riferimento)
          })

        console.log('FLATTTT')
        console.log(uniqueFlatted)
        console.log('FLATTTT')
        setCourses(uniqueFlatted.sort((x,y) => y.pertinenza - x.pertinenza));
      })
      .catch((err) => {
        // Handle errors
        console.error(err);
      });
  };

  /**
   * 
   * @returns non so
   */
  const submitData = async () => {
    let data = {}
    data.profile = profile
    data.survey = values
    data.courses = courses
    data.tags = tags
    data.lowRatingtags = lowRatingtags
    data.percent = percent

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    
    await axios.post(`${process.env.REACT_APP_API_URL}survey/submit`, data, {
        headers: headers
    })
    .then((response) => {
        //console.log(response)
        console.log('token: ', response.data)  
        setUserToken(response.data)    
        console.log('token commento:', userToken)
        // setCompiled(true)
    })
    .catch((error) => {
        console.log(error)
    })
  }

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  const restart = (e) => {
    e.preventDefault()
    setSeconds(0);
    setCourses([])
    setStep2Questions([])
    setTags([])
    setValues([])
    setStep(1);
    // setShowProfile(false);
    setlowRatingtags([]); //FIX di oggi
    setCurrentQuestion(questions[0]);
    setPercent([]) //FIX ORA
  };

  // const restart = () => {
  //   setSeconds(0);
  //   setCurrentQuestion(0);
  // };

  const handleChoice = async (currentQuestion, i) => {
    // let profiles = [...profiles]
    var newValues = [
      ...values,
      {
        question: currentQuestion.question,
        answerText: currentQuestion.answers[i].answer,
        tags: currentQuestion.answers[i].answer.tags, //new app
        profile: currentQuestion.answers[i].answer.profile, //new app
      },
    ];
    setValues(newValues);
    console.log(newValues);
    console.log(i);
    console.log(currentQuestion.answers[i]);
    if (currentQuestion.answers[i].question) {
      setCurrentQuestion(currentQuestion.answers[i]);
    } else {
      // alert('finito')
      let profilo = currentQuestion.answers[i].profile;
      setProfile(profilo);
      console.log("hai finito lo step 1!");
      console.log("il profilo è " + profilo);
      fetchQuestions(profilo).then(setStep(2));
      // setShowProfile(true);
      setCurrentQuestion(0); //0 -> step 2
    }
  };

  /**
   * Handler Step2 
   * @param {*} answer 
   * @param {*} question 
   */
  const handleAnswerOptionClick = async (answer, question) => {
    setLoading(true)
    if (answer) {
      var newValues = [
        ...values,
        {
          question: question.questionText,
          answerText: answer.answerText,
          tags: question.tag, //new app
          rating: answer.rating,
        },
      ];

      //chiamata ai corsi
      await fetchCourses(profile, question.tag, parseInt(answer.rating));
      // console.log("corsi >>> " + JSON.stringify(courses));
      
      setValues(newValues);
      tags.push({ tag: question.tag , rating: answer.rating });
      setTags(tags);
      //low ratings 0
      if(answer.rating === '0') {
        lowRatingtags.push({ tag: question.tag, rating: answer.rating });
        setlowRatingtags(lowRatingtags);
        console.log('---------TAG SCARTATI----------')
        console.log(lowRatingtags)
        console.log('---------N DI TAG SCARTATI------')
        console.log(lowRatingtags.length)
      }

      // console.log(tags);
      // console.log(newValues);
      const nextQuestion = currentQuestion + 1;
      setLoading(false)
      if (nextQuestion < step2Questions.length) {
        setCurrentQuestion(nextQuestion);
      } else {

        let profileQuestions = step2Questions.length //n domande
        let uselessTags = lowRatingtags.length // n risposte a 0

        let percent = (100 * uselessTags)/profileQuestions
        console.log('------PERCENTUALE-----')
        console.log(percent)
        console.log('------PERCENTUALE-----')
        setPercent(percent)
        setStep(3);
        await submitData();
      }
    }
  };

  const start = () => {
    setSeconds(0);
    setStep(1);
    // setShowProfile(false);
    setCurrentQuestion(questions[0]);
  };

  // Pre quiz landing step
  if (!step) {
    return <StartPage image={Circles} start={start} />;
  }

  if (step === 1) {
    //loading fase 1
    if (!questions) {
      return <Loading />;
    } else {
      return (
        <Step1
          key={currentQuestion}
          handleClick={handleChoice}
          questions={questions}
          currentQuestion={currentQuestion}
          seconds={seconds}
        />
      );
    }
  }
  //qui parte lo step 2 dinamico con dati da WP API
  if (step === 2) {
    if (!step2Questions.length) {
      return <Loading />;
    } else {
      if(!loading) {
        return (
          <Step2
            key={currentQuestion}
            profile={profile}
            handleClick={handleAnswerOptionClick}
            questions={step2Questions}
            currentQuestion={currentQuestion}
            seconds={seconds}
          />
        );
      } else {
        return <Loading />;     
      }

    }
  }

  if (step === 3) {
    
    if(percent > 50) {
      return <Noresult restart={restart} />;
    } else {
      if (!courses.length) {
        return <Loading />;
      } else {
        return (
          <Step3
            values={values}
            tags={tags}
            profile={profile}
            courses={courses}
            restart={restart}
          />
        );
      }
    }
    }


}

export default App;
