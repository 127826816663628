import React from "react";

const Question = ({
  handleClick,
  currentQuestion,
  questions,
  seconds,
  ...otherProps
}) => (
  <div className="container">
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col">
            {/* <div className="timer-text">{seconds}</div> */}
            <div className="question-count">
              <span>{currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">
              <h3 className="display-5">
                {questions[currentQuestion].questionText}
              </h3>
            </div>

            <div className="answer-section">
              <ul>
              {questions[currentQuestion].answerOptions.map((answer, i) => (
                <li 
                key={i}
                onClick={() =>
                  handleClick(answer, questions[currentQuestion])
                }>
                <button
                  className="answer-button"
                  key={i}
                >
                  {answer.answerText}
                </button>
                </li>
              ))}
              </ul>

            </div>
          </div>
          {/* <div className="col">
            <img
              src={questions[currentQuestion].img}
              alt=""
              className="img-fluid"
            />
          </div> */}
        </div>
      </div>
    </div>
  </div>
);

export default Question;
